import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import AboutStyle4 from '../About/AboutStyle4';
import FunFact from '../FunFact';
import AboutStyle5 from '../About/AboutStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import { pageTitle } from '../../helpers/PageTitle';
const funfactData = [
  { title: 'Weekly Views', number: '22k' },
  { title: 'Global Outreach', number: '632k+' },
  { title: 'Years of Experience', number: '20+' },
];

export default function AboutPage({ darkMode }) {
  pageTitle('About');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Driving Business Forward with <br>Tailored Software Solutions"
        subTitle="About Us"
        variant="text-center"
        shape="shape_1"
      />
      <Spacing lg="75" md="60" />
      <AboutStyle4
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715350101/Company-Growth_dvhedj.png"
        miniTitle="Company Info"
        title="Development agency for your business"
        subTitle="At Digitalbyte, we are dedicated to transforming business landscapes through superior software solutions. With a focus on custom software and web development, our team of expert developers and designers is committed to delivering state-of-the-art applications tailored to meet the unique needs of each client. We harness the latest technologies to build scalable, secure, and efficient systems that empower businesses to achieve their full potential. Whether streamlining operations, enhancing user experiences, or opening new digital avenues, Digitalbyte is your trusted partner in driving innovation and success. Our goal is to not only meet your immediate requirements but to also provide strategic insights and support that foster long-term growth and competitiveness in your industry."
        btnText="See Our Services"
        btnUrl="/service"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <AboutStyle5
        variant="cs_type_1"
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715352144/ISO15_pl5dm2.png"
        miniTitle="What We Do"
        title="Increasing your global footprint"
        subTitle="At Digitalbyte, we enhance your digital visibility through tailored web and software development solutions. Our SEO-friendly coding, advanced analytics, and responsive designs ensure your business stands out online. By analyzing your target audience and market trends, we strategically position your presence to captivate and engage users, boosting your digital footprint and attracting new opportunities."
        progressBarList={[
          { title: 'Software Development', percentage: '75' },
          { title: 'Brand Strategy', percentage: '85' },
          { title: 'Competitor Analysis', percentage: '95' },
        ]}
        salesTitle="Sales Increase"
        groth="25"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715355316/09_yp8kpr.jpg"
                  iconSrc="/images/icons/search.svg"
                  title="Research"
                  subTitle="In-depth research on the client's application and the selection of appropriate software stacks will enable us to identify potential development issues early in the process."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715355316/09_yp8kpr.jpg"
                  iconSrc="/images/icons/idea.svg"
                  title="System Design"
                  subTitle="We conduct a thorough design of the UI/UX, delving into the intricate details of how the deeper parts of the application will function, including database relationships and advanced routing."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715355316/09_yp8kpr.jpg"
                  iconSrc="/images/icons/gear.svg"
                  title="Dev & Implement"
                  subTitle="We develop the application based on specific needs, system designs, and research gathered. After thorough testing and client review, the application is then deployed into production."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Meet our experts team behind <br />the zivan agency"
            subTitle="Our Team"
          />
          <Spacing lg="85" md="45" />
        </div>
        <TeamSlider data={teamData} />
      </section> */}
      {/*   */}
    </>
  );
}

import React from 'react';
import Spacing from '../../Spacing';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../../Cta/CtaStyle2';
import AboutStyle6 from '../../About/AboutStyle6';
import CardStyle3 from '../../Card/CardStyle3';
import { pageTitle } from '../../../helpers/PageTitle';

export default function SoftwareDev() {
  pageTitle('Custom Software Development');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Software tailored to your unique needs"
        subTitle="Custom Software Development"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("https://res.cloudinary.com/dam8havko/image/upload/v1715607499/programming_clrvog.png")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Tailored"
                    subTitle="We specialize in crafting software solutions that are meticulously tailored to meet the unique requirements and specific goals of your business or project, ensuring optimal functionality and user experience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Efficient"
                    subTitle="Our custom software development focuses on creating efficient solutions that streamline processes, enhance productivity, and maximize resource utilization, delivering measurable improvements in operational efficiency."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Scalable"
                    subTitle="With scalability in mind, our solutions are designed to grow seamlessly alongside your business, adapting to increasing demands and evolving needs without compromising performance or reliability."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Secure"
                    subTitle="Security is paramount in our custom software development approach, integrating robust measures to safeguard your data, mitigate risks, and maintain compliance with industry standards, ensuring peace of mind for your organization."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715606841/13_agustus-outline-16_ux4a4e.png"
        title="What you will get from this service?"
        subTitle="Our custom software development service guarantees tailored solutions that precisely address your unique business needs and challenges, fostering efficiency and innovation. You'll receive a scalable and secure software application designed to enhance productivity, streamline operations, and support your long-term growth objectives effectively."
        featureList={[
          'Custom solutions tailored to your business needs',
          'Improved efficiency through optimized processes',
          'Scalable architecture for future growth',
          'Robust security measures for data protection',
        ]}
        // btnText="Check Live Projects"
        // btnUrl="/portfolio"
      />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}

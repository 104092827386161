import React from 'react';
import Spacing from '../../Spacing';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../../Cta/CtaStyle2';
import AboutStyle6 from '../../About/AboutStyle6';
import CardStyle3 from '../../Card/CardStyle3';
import { pageTitle } from '../../../helpers/PageTitle';

export default function MobileApps() {
  pageTitle('Mobile App Development');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Innovative Mobile App <br /> Development Solutions"
        subTitle="Mobile Application Development"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("https://res.cloudinary.com/dam8havko/image/upload/v1715608209/app_development_ka2ntf.png")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Agile"
                    subTitle="Our mobile app development process is agile, ensuring rapid iteration and continuous improvement based on user feedback, allowing for quick adaptation to changing market demands."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="User-centric"
                    subTitle="With a strong focus on user experience (UX) design, we create mobile apps that prioritize intuitive interfaces and seamless navigation, resulting in high user engagement and satisfaction."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Scalable"
                    subTitle="Our mobile apps are built with scalability in mind, capable of handling increased user loads and accommodating future feature enhancements to support your business growth effectively."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Secure"
                    subTitle="We implement robust security measures to safeguard user data and protect against cyber threats, ensuring compliance with stringent industry standards and regulations for peace of mind."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715606841/13_agustus-outline-16_ux4a4e.png"
        title="What you will get from this service?"
        subTitle="From our mobile app development service, you'll receive innovative applications tailored to your specific needs, designed to enhance user engagement and operational efficiency. Our solutions are built to be scalable, secure, and intuitive, ensuring they grow with your business while maintaining high standards of performance and usability."
        featureList={[
          'Custom mobile apps tailored to your needs',
          'Intuitive design for seamless user experience',
          'Scalable architecture to support future growth',
          'Strong security measures for data protection',
        ]}
        // btnText="Check Live Projects"
        // btnUrl="/portfolio"
      />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}

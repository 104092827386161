import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

export default function Layout({ darkMode }) {
  return (
    <div className={`${darkMode ? 'cs_dark' : ''}`}>
      <Header
        logoUrl={darkMode ? 'https://res.cloudinary.com/dam8havko/image/upload/v1713329483/Group_7_mqswod.svg' : '/images/logo.svg'}
        actionBtnText="Login | Register"
        actionBtnUrl="/contact"
      />
      <Outlet />
      <Footer />
    </div>
  );
}

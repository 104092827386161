import React from 'react';
import Spacing from '../Spacing';
import HeroStyle2 from '../Hero/HeroStyle2';
import Cta from '../Cta';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
import ServiceSlider from '../Slider/ServiceSlider';
import AboutStyle2 from '../About/AboutStyle2';
import FunFact from '../FunFact';
import { pageTitle } from '../../helpers/PageTitle';
const serviceData = [
  {
    iconSrc: '/images/marketing-agency/service_icon_1.svg',
    title: 'Custom Software Development',
    subTitle:
      "Tailored solutions designed specifically for your business needs, enhancing efficiency and delivering unique functionality that off-the-shelf software can't provide.",
    btnText: 'See More',
    btnUrl: '/service',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_2.svg',
    title: 'Web Development',
    subTitle:
      "Creating dynamic and scalable websites that provide excellent user experiences, designed to be responsive and functional across all devices.",
    btnText: 'See More',
    btnUrl: '/service',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_3.svg',
    title: 'Mobile App Development',
    subTitle:
      "Building intuitive and engaging mobile applications for iOS and Android platforms, helping you reach customers on their preferred devices.",
    btnText: 'See More',
    btnUrl: '/service',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_1.svg',
    title: 'UI/UX Design',
    subTitle:
      "Crafting visually appealing and user-friendly interfaces that improve user engagement and enhance the overall experience of your digital products.",
    btnText: 'See More',
    btnUrl: '/service',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_4.svg',
    title: 'Systems Integration Consulting',
    subTitle:
      "Systems Integration Consulting focuses on streamlining and optimizing the way disparate systems communicate and function together within an organization.",
    btnText: 'See More',
    btnUrl: '/service',
  },
];
const funfactData = [
  { title: 'Weekly Views', number: '22k' },
  { title: 'Global Outreach', number: '632k+' },
  { title: 'Years of Experience', number: '20+' },
];
const faqData = [
  {
    title: '01. What services do you offer?',
    content:
      'We specialize in custom software development, web development, mobile app development, cloud solutions, UI/UX design, API development, and legacy system modernization.',
  },
  {
    title: '02. How do you ensure the software you develop is secure?',
    content:
      'Security is a priority in every project we handle. We incorporate security best practices and protocols from the initial design to deployment, including regular security audits and compliance checks.',
  },
  {
    title: '03. Can you handle high traffic websites or applications?',
    content:
      'Yes, we design scalable solutions that can handle significant loads and spikes in user traffic, ensuring smooth performance during peak usage.',
  },
  {
    title: '04. What is your process for developing a new project?',
    content:
      'Our process typically includes requirement gathering, planning, design, development, testing, and deployment phases, followed by ongoing support and maintenance.',
  },
  {
    title: '05. How do you manage project timelines and budgets?',
    content:
      'We use project management methodologies like Agile to ensure that projects are completed on time and within budget, providing regular updates and adapting to changes efficiently.',
  },
  {
    title: '06. Can you integrate new software with our existing systems?',
    content:
      'Yes, we have expertise in API development and third-party integrations, ensuring that new software works seamlessly with your existing infrastructure.',
  },
  {
    title: '07. What post-launch support do you offer?',
    content:
      'We provide ongoing support and maintenance services to handle updates, fix any arising issues, and ensure your software continues to operate effectively.',
  },
];

export default function Home({ darkMode }) {
  pageTitle('Home');
  return (
    <>
      <HeroStyle2
        miniTitle="US Based Development Agency"
        title="Building Tomorrow's Web, Today."
        subTitle="Digitalbyte offers top-tier web development services designed to scale effortlessly to meet any demand. Our responsive solutions ensure optimal performance and a seamless user experience across all devices."
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715134752/5._MARKETING_-_INFOGRAPHIC_CHARTS_5_ljtubm.png"
        mikeIcon={
          darkMode ? '/images/icons/mike_dark.svg' : '/images/icons/mike.svg'
        }
      />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="143" md="75" />
        <SectionHeading title="Services we provide" subTitle="Our Services" />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={serviceData} />
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          uperTitle="Who We Are"
          title="Building Software That Powers Your Business"
          subTitle="At Digitalbyte, we specialize in developing customized software solutions that are tailored to the unique needs of your business. By understanding your challenges and objectives, we create systems that enhance productivity, streamline operations, and boost profitability. Our approach is focused on delivering technology that not only meets today's demands but also scales for future growth, ensuring your business remains competitive and agile in a rapidly evolving digital landscape."
          featureList={[
            'Scalable Architecture',
            'Enhanced Security',
            'Automated Workflows',
          ]}
          btnText="Learn More"
          btnUrl="/about"
        />
      </section>
      <section style={{ margin: '5rem 0', padding: '2rem 0'}} className='cs_primary_bg'>
        <div className="container">
          <Spacing md="70" />
          <FunFact data={funfactData} />
        </div>
      </section>
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}
      <section>
        {/* <Spacing lg="143" md="75" /> */}
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="https://res.cloudinary.com/dam8havko/image/upload/v1715231190/dje_3_ovjrcw.jpg"
          />
        </div>
      </section>
      <Spacing lg="135" md="70" />
    </>
  );
}

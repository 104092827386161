import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import TeamMember from '../TeamMember';
import { pageTitle } from '../../helpers/PageTitle';

export default function TeamPage() {
  pageTitle('Team');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Talented individuals <br/>dedicated to your success"
        subTitle="Team Members"
        shape="shape_4"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row cs_gap_y_35">
            <div className="col-lg-4 col-sm-6" >
              <TeamMember
                memberImg="/images/studio-agency/chase.png"
                memberName="Chase Hoffman"
                memberDesignation="Founder, CEO"
                href="/team-details"
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <TeamMember
                memberImg="/images/studio-agency/matt.png"
                memberName="Matt Little"
                memberDesignation="Founder, CTO"
                href="/team-details"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing lg="130" md="60" />
      {/* <section>
        <Spacing lg="130" md="60" />
        <div className="container">
          <SectionHeading
            title="Want to join our team?"
            subTitle="Check our open positions below!"
          />
          <Spacing lg="85" md="45" />
        </div>
        <Hiring
          thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715605471/Business_Team_Meeting_Isometric_mdcaqe.png"
          data={circularList}
        />
        <Spacing lg="150" md="80" />
      </section> */}
    </>
  );
}

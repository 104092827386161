import React from 'react';
import Spacing from '../../Spacing';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../../Cta/CtaStyle2';
import AboutStyle6 from '../../About/AboutStyle6';
import CardStyle3 from '../../Card/CardStyle3';
import { pageTitle } from '../../../helpers/PageTitle';

export default function UIDesign() {
  pageTitle('UI/UX Design');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Intuitive UI/UX Solutions for <br /> Seamless User Experiences"
        subTitle="UI/UX Design"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("https://res.cloudinary.com/dam8havko/image/upload/v1715608693/Website_Traffic_f4xeua.png")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="User-centric"
                    subTitle="User-centric design places the user's needs and preferences at the forefront, ensuring interfaces are tailored to enhance usability and satisfaction."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Intuitive"
                    subTitle="Intuitive design simplifies user interactions through thoughtful layout and clear navigation, ensuring a seamless user experience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Responsive"
                    subTitle="Our responsive design ensures optimal performance across all devices, adapting seamlessly to provide a consistent and user-friendly experience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Engaging"
                    subTitle="Engaging design captivates users by creating interactive and compelling experiences."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715606841/13_agustus-outline-16_ux4a4e.png"
        title="What you will get from this service?"
        subTitle="Our team, specializing in strategic digital marketing, partners with the world's leading brands. Breaking from the norm, we push boundaries and imaginative thinking, consumer behavior, and data-driven design with advanced."
        featureList={[
          'Prioritize user satisfaction and brand identity',
          'Intuitive interfaces for seamless usability',
          'Engaging designs to captivate and retain users',
          'Responsive layouts for consistent user experiences',
        ]}
        // btnText="Check Live Projects"
        // btnUrl="/portfolio"
      />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}

import React from 'react';
import Spacing from '../../Spacing';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../../Cta/CtaStyle2';
import AboutStyle6 from '../../About/AboutStyle6';
import CardStyle3 from '../../Card/CardStyle3';
import { pageTitle } from '../../../helpers/PageTitle';

export default function WebDev() {
  pageTitle('Web Development');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Customized Websites to <br />Elevate Your Online Presence"
        subTitle="Web Development"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("https://res.cloudinary.com/dam8havko/image/upload/v1715605944/Web_Design_vooeyi.png")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Reliable"
                    subTitle="Our reliable service ensures consistent performance and dependability through robust infrastructure, proactive maintenance, and responsive customer support, guaranteeing uninterrupted operation for your business needs."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Secure"
                    subTitle="We prioritize protecting your data and systems through advanced encryption, proactive monitoring, and regular security audits, ensuring robust defense against cyber threats and maintaining compliance with industry standards."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="UI/UX"
                    subTitle="Our UI/UX service focuses on creating intuitive and engaging user interfaces, enhancing user experience through thoughtful design, usability testing, and seamless interaction flows to maximize user satisfaction and retention."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="SEO"
                    subTitle="Our SEO service enhances online visibility through strategic keyword optimization, content refinement, and technical SEO improvements, aiming to drive organic traffic and improve search engine rankings effectively."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715606841/13_agustus-outline-16_ux4a4e.png"
        title="What you will get from this service?"
        subTitle="Our web development service ensures you receive a custom-built, responsive website tailored to your specific needs and goals.  You can expect clean, efficient code that enhances site performance, ensuring fast loading times and a smooth user experience.  Additionally, we provide comprehensive hosting services, offering reliable server infrastructure to keep your website secure and accessible around the clock."
        featureList={[
          'Custom-built, responsive websites',
          'A site tailored to your needs and goals',
          'Comprehensive hosting services',
          'Ongoing support and maintenance',
        ]}
        // btnText="Check Live Projects"
        // btnUrl="/portfolio"
      />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}

import React, { useState, useEffect } from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';
import axios from 'axios';

export default function ContactPage() {
  const [disabled, setDisabled] = useState(true);
  const [btnText, setBtnText] = useState('Submit');
  const [data, setData] = useState({
    fullName: '',
    email: '',
    projDesc: '',
    phone: '',
  });
  pageTitle('Contact');

  async function submitForm(e) {
    e.preventDefault()
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/forms/contact`, {
        email: data.email,
        fullName: data.fullName,
        projDesc: data.projDesc,
        phone: data.phone
      })
      if (res.status === 200) {
        setData({
          fullName: '',
          email: '',
          projDesc: '',
          phone: '',
        })
        setBtnText('Submitted')
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if(data.email !== '' && data.fullName !== '' && data.phone !== '' && data.projDesc !== '') {
      setDisabled(false);
    }
  }, [data]);

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
                Take your business <br />
                to the next level!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              {/* <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">info@digitalbyte.io</p>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form" onSubmit={(e) => submitForm(e)}>
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Your full name
                  </label>
                  <input
                    required
                    onChange={(e) => setData({...data, fullName: e.target.value})}
                    value={data.fullName}
                    placeholder="Type your name"
                    type="text"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Give valid email
                  </label>
                  <input
                    required
                    onChange={(e) => setData({...data, email: e.target.value})}
                    value={data.email}
                    placeholder="Type your email address"
                    type="email"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Phone number
                  </label>
                  <input
                    required
                    onChange={(e) => setData({...data, phone: e.target.value})}
                    value={data.phone}
                    placeholder="Type your phone number"
                    type="number"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Project brief
                  </label>
                  <textarea required onChange={(e) => setData({...data, projDesc: e.target.value})} value={data.projDesc} cols={30} rows={5} className="cs_form_field" />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button disabled={disabled} className="cs_btn cs_style_1" style={disabled === true ? { cursor: 'not-allowed'} : null }>
                    {btnText}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
}

import React from 'react';
import Spacing from '../../Spacing';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../../Cta/CtaStyle2';
import AboutStyle6 from '../../About/AboutStyle6';
import CardStyle3 from '../../Card/CardStyle3';
import { pageTitle } from '../../../helpers/PageTitle';

export default function SystemIntegration() {
  pageTitle('Systems Integration Consulting');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Streamlined Systems Integration <br />Consulting Solutions"
        subTitle="Systems Integration Consulting"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("https://res.cloudinary.com/dam8havko/image/upload/v1715608517/AllIsometricDEC16-25_eiqc8a.png")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Coordination"
                    subTitle="Our systems integration consulting ensures seamless coordination between your existing technologies, enhancing workflow efficiency and reducing operational redundancies."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Alignment"
                    subTitle="We align your systems with strategic business goals, optimizing integration processes to foster cohesive and synchronized operations across your organization."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Optimization"
                    subTitle="Through meticulous analysis and strategic implementation, we optimize your system integration for enhanced performance and productivity, leveraging synergies between disparate platforms."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Efficiency"
                    subTitle="Our consulting services focus on improving overall system efficiency, streamlining processes to minimize costs and maximize resource utilization effectively."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="https://res.cloudinary.com/dam8havko/image/upload/v1715606841/13_agustus-outline-16_ux4a4e.png"
        title="What you will get from this service?"
        subTitle="From our systems integration consulting service, you'll receive expert guidance and strategies tailored to seamlessly unify your diverse technologies, ensuring enhanced operational efficiency and streamlined workflows. We prioritize optimizing your systems to align with your business objectives, fostering improved collaboration, data accessibility, and overall organizational performance."
        featureList={[
          'Custom strategies for seamless system integration',
          'Expert guidance to optimize workflows efficiently',
          'Alignment of systems with business goals',
          'Enhanced collaboration and data access',
        ]}
        btnText="Check Live Projects"
        btnUrl="/portfolio"
      />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
